/* TotemHealthLab.css */
/* Styles for the Totem Health Lab component with responsive design aligned to Auren.css */

.totem-container {
    text-align: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

/* Team Section */
.totem-team-section {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 200px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.totem-team-title {
    font-size: 56px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px;
    max-width: 50%;
}

.totem-team-description {
    font-size: 20px;
    font-weight: 300;
    color: #bbb;
    line-height: 1.6;
    max-width: 50%;
    margin: 0 auto 40px auto;
}

.totem-team-image-container {
    width: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.totem-team-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Features Section */
.totem-features-section {
    text-align: center;
    padding: 200px 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100vw;
}

.totem-features-title {
    font-size: 56px;
    font-weight: bold;
    color: #000;
    line-height: 1.3;
    margin: 0;
    max-width: 40%;
}

.totem-features-subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #666;
    line-height: 1.6;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
}

.totem-feature-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    margin: 40px auto;
    max-width: 1200px;
    padding: 0 20px;
    box-sizing: border-box;
}

.totem-feature-block {
    max-width: 300px;
    text-align: center;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.totem-feature-icon {
    font-size: 40px;
    margin-bottom: 15px;
    color: #666;
}

.totem-feature-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.totem-feature-description {
    font-size: 16px;
    font-weight: 300;
    color: #666;
    line-height: 1.5;
    text-align: justify;
}

/* Timeline Section */
.totem-timeline-section {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 200px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.totem-timeline-title {
    font-size: 56px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 40px;
}

.totem-timeline {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
}

.totem-timeline::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: #fff;
    transform: translateX(-50%);
}

.totem-timeline-item {
    position: relative;
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
}

.totem-timeline-item.left {
    text-align: right;
    padding-right: 3rem;
    transform: translateY(-40px);
}

.totem-timeline-item.right {
    float: right;
    text-align: left;
    padding-left: 3rem;
}

.totem-timeline-content {
    position: relative;
    background-color: #111;
    border-radius: 6px;
    padding: 20px;
    display: inline-block;
    max-width: 100%;
}

.totem-timeline-content::before {
    content: '';
    position: absolute;
    top: 20px;
    border: 10px solid transparent;
}

.totem-timeline-item.left .totem-timeline-content::before {
    right: 0;
    border-right: none;
    border-left-color: #111;
    transform: translateX(100%);
}

.totem-timeline-item.right .totem-timeline-content::before {
    left: 0;
    border-left: none;
    border-right-color: #111;
    transform: translateX(-100%);
}

.totem-timeline-text {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    text-align: left;
}

.totem-timeline-item::after {
    display: none;
}

/* Responsive Adjustments */

/* Tablet View */
@media (max-width: 1024px) {
    .totem-team-title {
        font-size: 48px;
        max-width: 70%;
    }

    .totem-team-description,
    .totem-features-subtitle,
    .totem-timeline-text {
        max-width: 70%;
    }

    .totem-features-title {
        font-size: 48px;
        max-width: 60%;
    }

    .totem-feature-grid {
        max-width: 1000px;
    }

    .totem-feature-block {
        max-width: 45%;
    }

    .totem-timeline-title {
        font-size: 48px;
    }

    .totem-timeline-text {
        font-size: 16px;
    }
}

/* Mobile View */
@media (max-width: 768px) {
    .totem-team-section,
    .totem-features-section,
    .totem-timeline-section {
        padding: 80px 15px; /* Adjusted padding to match Auren.css */
    }

    .totem-team-title {
        font-size: 36px;
        max-width: 90%;
    }

    .totem-team-description,
    .totem-features-subtitle,
    .totem-timeline-text {
        max-width: 90%;
        font-size: 18px;
    }

    .totem-team-image {
        max-width: 100%;
    }

    .totem-features-title {
        font-size: 36px;
        max-width: 90%;
    }

    .totem-feature-grid {
        flex-direction: column;
        align-items: center;
    }

    .totem-feature-block {
        max-width: 100%;
    }

    .totem-timeline::before {
        left: 31px;
    }

    .totem-timeline-item {
        width: 100%;
        float: none;
        padding-left: 70px;
        padding-right: 25px;
    }

    .totem-timeline-item.left,
    .totem-timeline-item.right {
        text-align: left;
        padding-right: 25px;
        padding-left: 70px;
        transform: none;
    }

    .totem-timeline-content::before {
        left: -10px;
        border-left-color: #111;
        border-right-color: transparent;
        transform: none;
    }
}

/* Small Mobile View */
@media (max-width: 480px) {
    .totem-team-section,
    .totem-features-section,
    .totem-timeline-section {
        padding: 60px 10px; /* Adjusted padding to match Auren.css */
    }

    .totem-team-title {
        font-size: 28px;
        max-width: 100%;
    }

    .totem-team-description,
    .totem-features-subtitle,
    .totem-timeline-text {
        font-size: 16px;
        max-width: 100%;
    }

    .totem-features-title {
        font-size: 28px;
        max-width: 100%;
    }

    .totem-feature-title {
        font-size: 20px;
    }

    .totem-feature-description {
        font-size: 14px;
    }

    .totem-timeline-title {
        font-size: 28px;
    }

    .totem-timeline-text {
        font-size: 14px;
    }
}