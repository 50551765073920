/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-item {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  text-decoration: none;
  padding: 5px 10px;
  transition: border-bottom 0.3s ease, color 0.3s ease;
}

.navbar-item.active {
  color: #000000;
  border-bottom: 2px solid #000000;
}

.navbar-item:hover {
  color: #000000;
  border-bottom: 2px solid #e0e0e0;
}

/* Estilo minimalista con bordes y transiciones suaves */