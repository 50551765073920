/* Support.css */
.support-container {
  padding: 40px;
  text-align: center;
  background-color: #f9f9f9;
}

.support-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.support-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.support-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.support-image {
  max-width: 100%;
  height: auto;
}

.support-contact {
  font-size: 1rem;
  color: #333;
}

.support-legal-buttons {
  margin-top: 20px;
}

.legal-button {
  background: none;
  border: none;
  color: #999;
  font-size: 1.0rem;
  cursor: pointer;
  margin: 0 5px;
  padding: 2px 4px;
}

.legal-button:hover {
  color: #666;
  text-decoration: underline;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  width: 80%;
  max-height: 80%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  border-radius: 5px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.modal-body {
  margin-top: 30px;
  text-align: left;
}

.legal-text {
  white-space: pre-wrap;
  font-family: inherit;
  font-size: 0.9rem;
  line-height: 1.4;
}