/* Footer.css */
.footer-container {
    background-color: #f8f8f8;
    padding: 30px 20px;
    border-top: 1px solid #e7e7e7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-content {
    max-width: 1200px;
    display: flex;
    justify-content: space-between; /* Mantener separación horizontal */
    flex-wrap: wrap;
    text-align: center;
    color: #333;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px 60px; /* Incrementado para mayor separación horizontal */
}

.footer-section h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #000;
}

.footer-section p {
    margin: 5px 0;
}

.footer-section a {
    color: #555555; /* Color gris oscuro para enlaces */
    text-decoration: underline 1px solid rgba(0, 0, 0, 0.2); /* Subrayado tenue */
}

.footer-section a:hover {
    color: #333333; /* Color más oscuro al pasar el mouse */
    text-decoration: underline 1px solid rgba(0, 0, 0, 0.4); /* Subrayado un poco más oscuro */
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #666;
}

.footer-bottom p {
    margin: 0;
}

@media (max-width: 600px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
    .footer-section {
        margin: 10px 0;
    }
}

/* No changes needed in Footer.css */