/* /Users/js/devAir/thlweb/thl/src/page/Auren/Auren.css */
/* Auren.css - Main styles for the Auren component */

/* Contenedor principal */
.auren-container {
    text-align: center;
    padding: 200px 0px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

/* Título principal */
.auren-title {
    font-size: 60px;
    font-weight: bold;
    color: #000000;
    line-height: 1.4;
    max-width: 50%;
    margin: 0 auto;
    padding-bottom: 40px;
}

/* Contenedor de imagen principal */
.auren-image-container {
    margin-top: 40px;
}

.auren-image {
    max-width: 80%;
    height: auto;
    border-radius: 10px;
}

/* Descripción principal */
.auren-description {
    font-size: 20px;
    font-weight: 300;
    color: #333333;
    line-height: 1.6;
    margin-top: 20px;
    max-width: 50%;
    text-align: center;
}

/* Texto de descarga */
.auren-download-text {
    font-size: 20px;
    font-weight: 300;
    color: #666666;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}

/* Contenedor de imagen de descarga */
.auren-download-image-container {
    margin-top: 10px;
    padding-bottom: 100px;
}

.auren-download-image {
    max-width: 200px;
    height: auto;
}

/* Nueva sección */
.auren-new-section {
    text-align: center;
    padding: 100px 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

/* Título de la nueva sección */
.auren-new-section-title {
    font-size: 56px;
    font-weight: bold;
    color: #000;
    line-height: 1.3;
    margin: 0;
    max-width: 40%;
}

/* Subtítulo de la nueva sección */
.auren-new-section-subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #666;
    line-height: 1.6;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
}

/* Contenedor de las características */
.auren-feature-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    margin: 40px auto;
    max-width: 1200px;
    padding: 0 20px;
    box-sizing: border-box;
}

/* Bloques de texto */
.auren-feature-block {
    max-width: 300px;
    text-align: center;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Icono del bloque */
.auren-feature-icon {
    font-size: 40px;
    margin-bottom: 15px;
    color: #666;
}

/* Títulos de los bloques */
.auren-feature-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Descripción de los bloques */
.auren-feature-description {
    font-size: 16px;
    font-weight: 300;
    color: #666;
    line-height: 1.5;
    text-align: justify;
}

/* Breast Cancer Predict Section */
.auren-breast-section {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.auren-breast-section-title {
    font-size: 56px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px;
}

.auren-breast-section-subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #bbb;
    line-height: 1.6;
    max-width: 50%;
    margin: 0 auto 40px auto;
}

/* Contenedor grid para la sección Breast Cancer */
.auren-breast-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
}

/* Ajustes específicos para la imagen de cáncer de mama */
.auren-breast-image-container {
    width: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
}

.auren-breast-image {
    max-width: 100%;
    height: auto;
}

/* Títulos y descripciones de la sección Breast */
.auren-breast-title {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
}

.auren-breast-description {
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    line-height: 1.5;
    text-align: justify;
    max-width: 50%;
}

.auren-breast-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px auto;
}

.breast-feature-icon {
    font-size: 40px;
    margin-bottom: 15px;
    color: #c6c6c6;
    text-align: center;
}

/* Advanced Cellular Analysis Section */
.auren-container:last-of-type {
    padding-bottom: 100px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .auren-title {
        font-size: 48px;
        max-width: 70%;
    }

    .auren-description,
    .auren-new-section-subtitle,
    .auren-breast-description {
        max-width: 70%;
    }

    .auren-new-section-title {
        font-size: 48px;
        max-width: 60%;
    }

    .auren-feature-grid {
        max-width: 1000px;
    }

    .auren-feature-block {
        max-width: 45%;
    }

    .auren-breast-section-title {
        font-size: 48px;
    }

    .auren-breast-title {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .auren-container {
        padding: 80px 15px;
    }

    .auren-title {
        font-size: 36px;
        max-width: 90%;
    }

    .auren-description,
    .auren-new-section-subtitle,
    .auren-breast-description {
        max-width: 90%;
        font-size: 18px;
    }

    .auren-image {
        max-width: 100%;
    }

    .auren-new-section {
        padding: 80px 15px;
    }

    .auren-new-section-title {
        font-size: 36px;
        max-width: 90%;
    }

    .auren-feature-grid {
        flex-direction: column;
        align-items: center;
    }

    .auren-feature-block {
        max-width: 100%;
    }

    .auren-breast-section {
        padding: 80px 0px;
    }

    .auren-breast-section-title {
        font-size: 36px;
    }

    .auren-breast-grid {
        max-width: 100%;
    }

    .auren-breast-description {
        max-width: 90%;
    }

    .auren-download-image {
        max-width: 150px;
    }
}

@media (max-width: 480px) {
    .auren-container {
        padding: 60px 10px;
    }

    .auren-title {
        font-size: 28px;
        max-width: 100%;
    }

    .auren-description,
    .auren-new-section-subtitle,
    .auren-breast-description {
        font-size: 16px;
        max-width: 100%;
    }

    .auren-new-section-title {
        font-size: 28px;
        max-width: 100%;
    }

    .auren-feature-title {
        font-size: 20px;
    }

    .auren-feature-description {
        font-size: 14px;
    }

    .auren-breast-section-title {
        font-size: 28px;
    }

    .auren-breast-title {
        font-size: 18px;
    }

    .auren-download-image {
        max-width: 120px;
    }
}